export const formatDate = (dateStr, isTime) => {
  // Create a new Date object from the string
  const d = new Date(dateStr);

  // Calculate IST (UTC+5:30) by adding the offset
  // const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
  const localDate = new Date(d.getTime());

  const pad = (n) => n.toString().padStart(2, "0");

  // Format the date as DD-MM-YYYY in UTC
  const formattedDate = `${pad(localDate.getDate())}-${pad(
    localDate.getMonth() + 1
  )}-${localDate.getFullYear()}`;

  if (isTime) {
    // Format the time in 12-hour format with AM/PM in UTC
    const hours = localDate.getHours();
    const minutes = localDate.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12 > 9 ? hours12 : `0${hours12}`}:${
      minutes > 9 ? minutes : `0${minutes}`
    } ${period}`;

    return `${formattedDate} || ${formattedTime}`;
  }

  return `${formattedDate}`;
};

export const randomColor = () => {
  return `rgb(${getRandomNumber(100, 255)}, ${getRandomNumber(
    0,
    100
  )}, ${getRandomNumber(0, 255)})`;
};

export const getRandomNumber = (min, max) => {
  // Ensure min and max are integers and min is less than max
  min = Math.ceil(min);
  max = Math.floor(max);

  // Generate a random number between min and max, inclusive
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
