import { FaInstagram, FaTelegram, FaWhatsapp } from "react-icons/fa";

export const socialMediaLinks = [
  {
    href: "https://t.me/sarkarijobsamachardotcom",
    icon: FaTelegram,
    bg: "linkedin.500",
    hoverBg: "linkedin.600",
    name: "Telegram",
  },

  {
    href: "https://www.instagram.com/sarkarijobsamacharofficial?igsh=NjVnM3k4cjltamE2",
    icon: FaInstagram,
    bg: "red.500",
    hoverBg: "red.600",
    name: "Instagram",
  },
  {
    href: "https://whatsapp.com/channel/0029VakgPzcB4hdYEeVouX2f", // Replace with your WhatsApp link if needed
    icon: FaWhatsapp,
    bg: "green.500",
    hoverBg: "green.600",
    name: "Whatsapp",
  },
];
export const initialStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
];
export const GovTypes = ["central", "state", "other"];
export const Sectors = [
  "upsc",
  "ssc",
  "psc",
  "bank",
  "railway",
  "Police_and_Law Enforcement",
  "Education_and_Teaching",
  "engineering/psus",
  "medical",
  "defence",
  "Law_and_Justice",
  "Agriculture_and_Rural Development",
  "Science_and_Research",
  "Social Welfare_and_Public Distribution",
  "Environment_and_Wildlife",
  "other",
];
export const apply_mode = ["online", "offline", "email", "walkin", "other"];
export const job_type = [
  "regular",
  "contractual",
  "both_rc",
  "internship",
  "other",
];
export const empty = "<p><br></p>";

export const Sectors_btn = [
  { name: "UPSC", value: "upsc" },
  { name: "SSC", value: "ssc" },
  { name: "Railway", value: "railway" },
  { name: "Bank", value: "bank" },
  { name: "Police", value: "Police_and_Law Enforcement" },
  { name: "Engineer/PSU", value: "engineering" },
  { name: "Defence", value: "defence" },
  { name: "Education/Teaching", value: "Education_and_Teaching" },
  { name: "Law", value: "Law_and_Justice" },
  { name: "Finance", value: "Agriculture_and_Rural Development" },
  { name: "Medical", value: "medical" },
  { name: "Other", value: "other" },
];
